@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@300;400;500;600;700;800&display=swap');


.text-de {
    font-family: 'Noto Sans Thai', sans-serif;
    color: #fff;
    font-size: 14px;
    margin: auto;
    text-align: center;
    padding: 1rem;
}

.titxa {
    font-size: 5rem;
    font-weight: 600;
    margin: 5px;

}

.text-de p {
    font-size: 1.2rem;
    line-height: 2rem;
    padding: 2rem;
}

.text-de p strong {
    font-size: 3rem;
    line-height: 2rem;
    font-weight: 600;
}

.text-de small {
    font-size: 1rem;
}



.gtiisk {
    padding: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5px;
    margin-top: 10rem;
    align-items: center;
    width: 100%;
    max-width: 60%;
}

.slooo {
    width: 100%;
    max-width: 60%;
    display: flex;
    justify-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: nowrap;
    margin: auto;
    padding: 10px;
}

.slooo img {
    width: 100%;
}

.gtiisk img {
    border-radius: 15px;
    width: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}





.voltage-button {
    position: relative;
}

.voltage-button img {
    color: white;
    background: #0D1127;
    /* padding: 1rem 3rem 1rem 3rem; */
    border-radius: 2rem;
    border: 1px solid #5978F3;
    transition: background 0.3s;

}

.voltage-button img:hover {
    cursor: pointer;
    background: #0F1C53;

}

.voltage-button img:hover+svg,
.voltage-button img:hover+svg+.dots {
    opacity: 1;
}


.voltage-button svg {
    display: block;
    position: absolute;
    top: -0.75em;
    left: -0.25em;
    width: calc(100% + 0.5em);
    height: calc(100% + 1.5em);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s;
    transition-delay: 0.1s;

}

.voltage-button svg path {
    stroke-dasharray: 100;
    filter: url("#glow");

}

.voltage-button svg path.line-1 {
    stroke: #f6de8d;
    stroke-dashoffset: 0;
    animation: spark-1 3s linear infinite;
}

.voltage-button svg path.line-2 {
    stroke: #6bfeff;
    stroke-dashoffset: 500;
    animation: spark-2 3s linear infinite;
}

.voltage-button .dots {
    opacity: 0;
    transition: opacity 0.3s;
    transition-delay: 0.4s;
}

.voltage-button .dots .dot {
    width: 1rem;
    height: 1rem;
    background: white;
    border-radius: 100%;
    position: absolute;
    opacity: 0;
}

.voltage-button .dots .dot-1 {
    top: 0;
    left: 20%;
    animation: fly-up 3s linear infinite;
}

.voltage-button .dots .dot-2 {
    top: 0;
    left: 55%;
    animation: fly-up 3s linear infinite;
    animation-delay: 0.5s;
}

.voltage-button .dots .dot-3 {
    top: 0;
    left: 80%;
    animation: fly-up 3s linear infinite;
    animation-delay: 1s;
}

.voltage-button .dots .dot-4 {
    bottom: 0;
    left: 30%;
    animation: fly-down 3s linear infinite;
    animation-delay: 2.5s;
}

.voltage-button .dots .dot-5 {
    bottom: 0;
    left: 65%;
    animation: fly-down 3s linear infinite;
    animation-delay: 1.5s;
}



@keyframes spark-1 {
    to {
        stroke-dashoffset: -1000;
    }
}

@keyframes spark-2 {
    to {
        stroke-dashoffset: -500;
    }
}

@keyframes fly-up {
    0% {
        opacity: 0;
        transform: translateY(0) scale(0.2);
    }

    5% {
        opacity: 1;
        transform: translateY(-1.5rem) scale(0.4);
    }

    10%,
    100% {
        opacity: 0;
        transform: translateY(-3rem) scale(0.2);
    }
}

@keyframes fly-down {
    0% {
        opacity: 0;
        transform: translateY(0) scale(0.2);
    }

    5% {
        opacity: 1;
        transform: translateY(1.5rem) scale(0.4);
    }

    10%,
    100% {
        opacity: 0;
        transform: translateY(3rem) scale(0.2);
    }
}

.wwwa{
    width: 100%;
}

.ray {
    clip-path: polygon(0% 45%, 100% 0%, 100% 100%, 0% 55%);
    transition: 5s;
    transform: translateY(-50%) translateX(-35%) rotate(var(--angle)) translateY(50%) translateX(50%) translateY(-50%);
    position: absolute;
    top: -30px;
    left: 70%;
    width: 80%;
    height: 280px;
    background: linear-gradient(0.25turn, rgba(255, 250, 255, 0.7), rgba(255, 255, 100, 0));
}


.light1 {
    animation-duration: 10s;
    animation-name: wave;
    animation-iteration-count: infinite;
    /* left: "100px" */
}

.light2 {
    animation-duration: 7s;
    animation-name: wave;
    animation-iteration-count: infinite;
    /* right: "100px" */
}


@keyframes wave {
    0% {
        --angle: 90deg;
    }

    50% {
        --angle: 110deg;
    }

    100% {
        --angle: 60deg;
    }
}

@media only screen and (max-device-width: 480px) {
    #llx {
        left: 40px !important;
    }

    #rrx {
        left: 250px !important;
    }

}


@media only screen and (max-device-width: 480px) {

    .text-de p {
        font-size: 1.2rem;
        line-height: 2rem;
        padding: 5px;
    }

    .text-de p strong {
        font-size: 2rem;
        line-height: 2rem;
        font-weight: 600;
    }

    .text-de small {
        font-size: 1rem;
    }

    .gtiisk {
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        margin-top: 5rem;
        align-items: center;
        max-width: 90%;
    }



    .titxa {
        font-size: 3rem;
        font-weight: 600;
        margin: 5px;
    }

    .slooo {
        width: 100%;
        max-width: 90%;
    }

    .voltage-button img {

        /* padding: 1rem 3rem 1rem 3rem; */
        border-radius: 0.5rem;
        border: 1px solid #5978F3;

    }

    .ray {
        
        width: 80%;
        height: 80px;
    }

    .light1 {
        animation-duration: 10s;
        animation-name: wave;
        animation-iteration-count: infinite;
        left: "-50px"
    }
    
    .light2 {
        animation-duration: 7s;
        animation-name: wave;
        animation-iteration-count: infinite;
        left: "500px"
    }

}